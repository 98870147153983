import React, { useEffect } from "react";
import gsap, { Power0 } from "gsap";
import classNames from "classnames";
import LazyImage from "../../lazy-image";

interface Customer {
  name: string;
  logo: string;
  storeLink: string;
}

interface IProps {
  list: Customer[];
  spacing?: "sm" | "lg";
  whiteCards?: boolean;
}

const ContentScroller: React.FC<IProps> = ({ list, spacing = "sm", whiteCards = false }) => {
  const wrapperClassnames = classNames("w-full");

  return (
    <div className={wrapperClassnames}>
      <div className={`section-1 flex marquee-container full relative h-15 sm:h-16 md:h-20 left-0`}>
        <ScrollerList customers={list} index={0} />
        <ScrollerList customers={list} index={1} />
      </div>
    </div>
  );
};

const ScrollerList: React.FC<{ customers?: Customer[]; index: number }> = ({ customers, index }) => {
  return (
    <ul
      className={`flex items-center justify-between mx-auto py-2 px-[5.5vw] sm:px-[4.5vw] lg:py-3 lg:px-[3.5vw] xl:px-[3.8vw] marquee ${
        index === 1 ? "marquee2" : ""
      }`}
    >
      {customers?.map((customer, i) => (
        <li className="py-1.5" key={i}>
          <a href={customer.storeLink} target="_blank" rel="noreferrer" className="relative flex flex-col items-center">
            <figure className="h-11.25 sm:h-12.5 md:h-15 w-11.25 sm:w-12.5 md:w-15 flex-shrink-0 rounded-full overflow-hidden border-4 border-grey-border border-opacity-30 customer-card">
              <LazyImage
                src={customer.logo}
                alt={customer.name}
                className="h-full w-full object-cover"
                loaderClasses="rounded-full"
              />
              {/* <img  /> */}
            </figure>
            <div className="absolute top-[120%] bg-black text-1xs font-medium text-white px-2 py-1 rounded-20 transform translate-y-2 -translate-x-1/2 ease-out duration-200 opacity-0 pointer-events-none customer-card-name left-1/2">
              {customer.name}
            </div>
          </a>
        </li>
      ))}
    </ul>
  );
};

export default ContentScroller;
